/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook-logo': {
    width: 414,
    height: 412,
    viewBox: '0 0 414 412',
    data: '<path pid="0" d="M286.5 410.9V252.4h4.5c14.7 0 29.4-.1 44 .1 3.3 0 4.6-.9 5-4.2 1.9-15.6 3.9-31.2 5.9-46.8.5-3.7.9-7.4 1.5-11.7h-60.7c0-16.3-.4-32.2.2-48 .3-8.9 4.9-16.1 14.1-18.7 4.5-1.3 9.3-2 14.1-2.1 10.4-.3 20.8-.2 31.1-.1 2.6 0 3.5-.7 3.5-3.4-.1-16.5-.1-32.9 0-49.4 0-2.9-1.1-3.8-3.6-3.9-6.1-.4-12.1-1.1-18.2-1.2-13.2-.1-26.6-1-39.7.4-38 3.9-63.7 30.1-66.3 71.4-1.1 16.8-.5 33.6-.7 50.5 0 1.5-.2 3-.2 4.7h-4.3c-15.2 0-30.3.1-45.5-.1-3 0-3.9.9-3.8 3.8.1 18.3.1 36.5 0 54.8 0 3.2.9 4.1 4.1 4.1 15-.1 30.1-.1 45.1-.1h4.3v158.6" _fill="#fff"/>'
  }
})
